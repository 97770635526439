<template>
   <section class="alarmConfigurationalarm cont">
      <Breadcrumb :crumbs="crumbs"/>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <el-radio-group v-model="alarmType">
                     <el-radio-button label="">全部</el-radio-button>
                     <el-radio-button label="DEVICE_OFFLINE_ALARM">离线报警</el-radio-button>
                     <el-radio-button label="ROOM_PMS_ALARM">飞房报警</el-radio-button>
                  </el-radio-group>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)">搜索</el-button>
               <el-button  type="primary" plain @click="handleQuery(false)">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格按钮 -->
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary"  @click="handleAdd">添加规则</el-button>
               </el-row>
            </div>
            <!-- 表格 -->
            <el-table border fit v-loading="loading" :data="tableData" highlight-current-row style="width: 100%" >
<!--               <el-table-column label="酒店项目" prop="hotelProjectName"></el-table-column>-->
               <el-table-column label="常通电设备" prop="alwaysPoweredTitle"></el-table-column>
               <el-table-column label="非常通电设备" prop="notOftenPoweredTitle"></el-table-column>
               <el-table-column label="报警周期(分钟)" prop="alarmCycle"></el-table-column>
               <el-table-column label="操作" fixed="right">
                  <template slot-scope="scope">
                     <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                        <el-link type="primary">编辑</el-link>
                     </el-button>
                     <el-button type="text" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                        <el-link type="danger">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页  v-if="pageState" -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>

         </el-row>

      </el-row>

      <el-dialog :title="`${ title === 'add' ? '添加' : '编辑'}报警规则`" :visible.sync="isShow" width="600px" :before-close="handleClose" v-if="isShow">
         <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="right" label-width="150px">
            <el-form-item label="选择报警类型" prop="alarmType">
               <el-radio-group v-model="ruleForm.alarmType">
                  <el-radio label="DEVICE_OFFLINE_ALARM">离线报警</el-radio>
                  <el-radio label="ROOM_PMS_ALARM">飞房报警</el-radio>
               </el-radio-group>
            </el-form-item>
            <!--        prop="alarmCycle"-->
            <el-form-item label="设置报警周期(分钟)" >
               <el-input class="width-300" v-model="ruleForm.alarmCycle" placeholder="请输入" ></el-input>
            </el-form-item>
            <div v-if="ruleForm.alarmType === 'DEVICE_OFFLINE_ALARM'">
               <el-form-item label="选择常通电设备">
                  <el-select class="width-300" v-model="ruleForm.alwaysPowered" multiple filterable  placeholder="请选择"
                             @visible-change="(bool)=>{ visibleChange(bool,'WF') }">
                     <el-option
                           v-for="item in product_WF"
                           :key="item.productId"
                           :label="item.standard"
                           :value="item.productId"
                           :disabled="item.disabled_WF">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="选择非常通电设备">
                  <el-select class="width-300" v-model="ruleForm.notOftenPowered" multiple filterable  placeholder="请选择"
                             @visible-change="(bool)=>{ visibleChange(bool,'ZB') }">
                     <el-option
                           v-for="item in product_ZB"
                           :key="item.productId"
                           :label="item.standard"
                           :value="item.productId"
                           :disabled="item.disabled_ZB">
                     </el-option>
                  </el-select>
               </el-form-item>
            </div>
         </el-form>
          <span slot="footer" class="dialog-footer">
             <el-button @click="resetForm()">重置</el-button>
             <el-button type="primary" @click="handleSave('ruleForm')">保存</el-button>
          </span>
      </el-dialog>
   </section>
</template>

<script>
import { getAlarmRules , addEditAlarmRules , delAlarmRules , getGroupByProductIds } from "@/api/aiot/alarmManagement/alarmManagement"
import hotel from "../hotelManage/hotelList/hotel/hotel";
import { mapState } from "vuex";
export default {
   name: "alarmConfigurationalarm",
   data(){
      return{
         crumbs: new Map([['AIOT'], ['报警管理'], ['报警配置']]),
         alarmType:'',                      // 报警类型

         loading:true,        // 表格加载效果
         tableData: [],       // 表格数据
         total: 0,            // 用户列表总条目数
         page: 1,             // 当前页 默认第一页
         limit: 0,            // 每页显示数
         maps:new Map(),      // 映射 处理表格回显
         // pageState:true,

         title:'add',                        // 弹窗文本
         isShow:false,                       // 是否显示弹窗
         ruleForm:{
            hotelProjectId:'',               // 酒店项目id
            hotelId:'',                      // 酒店id
            alarmType:'DEVICE_OFFLINE_ALARM',// 报警类型
            alarmCycle:'',                   // 报警周期
            alwaysPowered:[],                // 常待电
            notOftenPowered:[],              // 非长待电
         },
         rules:{
            alarmType:[{ required: true, message: '请选择报警类型', trigger: 'change' }],
            // alarmCycle:[{ required: true,validator: validAlarmCycle, trigger: 'blur' }],
         },
         jobName:'',                        // 酒店项目名称
         product_WF:[],                     // WF-常待电数组
         product_ZB:[],                     // ZB-非长待电数组

         editId:'',                         // 编辑id
         jobId:'',                          // 编辑jobId

         add_success :'添加成功',
         edit_success:'编辑成功',
         prompt: '提示！',
         confirm_remove: '确定移除？',
         remove_success: '移除成功！',
      }
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.ruleForm.hotelId = this.hotelInfo.id
      this.getGroupByProductList()
      this.getAlarmRules('search')

   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   watch:{
      hotelInfo(newVal,oldVal){
         if(oldVal && newVal.id !== oldVal.id){
            this.getGroupByProductList()
            this.getAlarmRules('search')
         }
      }
   },
   methods:{
      // 请求通用设备
      getGroupByProductId(){
         return new Promise((resolve,reject)=>{
            getGroupByProductIds(this.hotelInfo.id).then(res=>{
               resolve(res)
            }).catch(err=>{
               reject(err)
            })
         })
      },

      // 设置(常/非常)通电设备 弹窗下拉框
      getGroupByProductList(){
         this.getGroupByProductId(this.hotelInfo.id).then(res=>{
            this.product_WF = res.records
            this.product_ZB = res.records
         })
      },

      // 搜索/重置
      handleQuery(bool){
        if(bool) return  this.getAlarmRules()
         this.alarmType = ''
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.page = 1
         this.getAlarmRules()
      },

      // 获取表格数据
      async getAlarmRules(state){
         if(!this.hotelInfo.hasOwnProperty('projectId')) {
            return this.loading = false
         }
         if(state === 'search'){
            await this.getGroupByProductId(this.hotelInfo.projectId).then(res=>{
               if(res.success){
                  res.records.forEach(item=>{ this.maps.set(item.productId,item.standard) })
               }
            })
         }
         let params = {
            limit:this.limit,
            page:this.page,
            hotelProjectId:this.hotelInfo.projectId,
            alarmType:this.alarmType      // 查询 全部/离线报警/飞房报警 ''/DEVICE_OFFLINE_ALARM/ROOM_PMS_ALARM
         }
         getAlarmRules(params).then((res)=>{
            if(res.success){
               this.pageState = true
               this.loading = false
               this.total = res.total
               let resData = res.records
               if(resData.length > 0){
                  resData.forEach((item)=>{
                     // 表格(非常/常)通电设备回显  非离线报警无(非常/常)通电设备
                     if(item.alarmType === 'DEVICE_OFFLINE_ALARM'){
                        let notOftenArr = []
                        let alwaysArr = []
                        if(item.hasOwnProperty('notOftenPowered')) item.notOftenPowered.forEach(items=>{notOftenArr.push(this.maps.get(items))})
                        item.notOftenPoweredTitle = notOftenArr.join('、')
                        if(item.hasOwnProperty('alwaysPowered')) item.alwaysPowered.forEach(items=>{ alwaysArr.push(this.maps.get(items)) })
                        item.alwaysPoweredTitle = alwaysArr.join('、')
                     }
                  })
               }
               this.tableData = res.records
            }
         })
      },

      visibleChange(bool,state){
         if(bool){
            if(!this.ruleForm.hotelId) {
               this.product_WF = []
               this.product_ZB = []
               return
            }
         }
         // 常通电设备
         if(state === 'WF'){
            this.product_WF.forEach((item,index)=>{
               item.disabled_WF = false
               if(this.ruleForm.notOftenPowered.includes(item.productId)) {
                  item.disabled_WF = true
                  this.$set(this.product_WF, index, item );
               }
            })
         }else{
            // 非常通电设备
            this.product_ZB.forEach((item,index)=>{
               item.disabled_ZB = false
               if(this.ruleForm.alwaysPowered.includes(item.productId)) {
                  item.disabled_ZB = true
                  this.$set(this.product_ZB, index, item );
               }
            })
         }
      },
      // 关闭弹窗
      handleClose(){
         this.isShow = false
         this.resetForm()
      },
      // 重置弹窗
      resetForm(){
         for (const k in this.ruleForm) this.ruleForm[k] = ''
         this.ruleForm.hotelId = this.hotelInfo.id
         this.ruleForm.hotelProjectId = this.hotelInfo.projectId
         //
         this.ruleForm.alarmType = 'DEVICE_OFFLINE_ALARM'
         this.ruleForm.alwaysPowered = []
         this.ruleForm.notOftenPowered = []
         this.ruleForm.job = {}

         this.product_WF.forEach((item)=>{ item.disabled_WF = false})
         this.product_ZB.forEach((item)=>{ item.disabled_ZB = false})

      },

      // 添加规则
      handleAdd(){
         this.title = 'add'
         this.isShow = true
      },
      // 编辑
      handleEdit(row){
         this.title = 'edit'
         // 回显
         for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k])
         this.isShow = true
         this.editId = row.id
         this.jobId = row.jobId
      },
      // 保存
      handleSave(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               if(!this.hotelInfo.hasOwnProperty('projectId')) return this.$message.warning("未绑定酒店，请前往酒店管理进行绑定"),this.isShow = false
               this.ruleForm.hotelProjectId = this.hotelInfo.projectId
               this.ruleForm.hotelId = this.hotelInfo.id
               let data = this.ruleForm
               data.job = {
                  // cronExpression: `0 */${this.ruleForm.alarmCycle} * * * ?`,
                  jobClassName: this.ruleForm.alarmType,
                  jobGroup: 0,
                  jobName:this.jobName ,
                  state: "RUNNING",
               }
               if(this.ruleForm.alarmType === 'DEVICE_OFFLINE_ALARM'){
                  data.job.cronExpression = `0 */${this.ruleForm.alarmCycle} * * * ?`
               }else if(this.ruleForm.alarmType === 'ROOM_PMS_ALARM'){
                  delete data.alwaysPowered     // 常用
                  delete data.notOftenPowered   // 非常用
                  data.job.cronExpression = `0 */${this.ruleForm.alarmCycle} * * * ?`
                  // data.job.cronExpression = `0 0 0/${Math.round(this.ruleForm.alarmCycle/60)} * * ?`
               }
               if(this.ruleForm.alarmCycle >= 60) data.job.cronExpression = this.ruleForm.alarmCycle

               if(this.title === 'edit'){
                  data.id = this.editId
                  data.job.id = this.jobId
               }
               addEditAlarmRules(data,this.title).then(res=>{
                  if(res.success){
                     this.$message.success(this.title === 'add' ? this.add_success : this.edit_success);
                     this.isShow = false
                     this.getAlarmRules()
                  }
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         })
      },
      // 删除
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'error'
         }).then(() => {
            let data = { id:id }
            delAlarmRules(data).then(res=>{
               if (res.success) {
                  this.$message.success({showClose: true, message: this.remove_success, })
                  this.getAlarmRules()
               }
            })
         })
      },

      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getAlarmRules()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getAlarmRules()
      },
   }
}
</script>
